<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    transition="dialog-bottom-transition"
    v-if="isDataLoadedFromServer"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins h-100 overflow-hidden">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Edit Template</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body mx-5 mx-xl-15 py-4 my-0 py-0">
        <!--begin::Body-->
        <form
          id="createForm"
          class="row overflow-y-auto scrollable h-100 pt-2 pb-12"
          style="overflow-y: scroll; max-height: 96vh"
        >
          <div
            class="col-12 col-sm-6 py-0 my-0"
            v-if="serverData?.organizations?.length > 0"
          >
            <v-select
              v-model="formData.organization_id"
              label="Organizaiton"
              item-text="text"
              item-value="index"
              :items="serverData.organizations"
              clearable
              outlined
              dense
              required
              :error-messages="organization_idErrors"
              @input="$v.formData.organization_id.$touch()"
              @blur="$v.formData.organization_id.$touch()"
            ></v-select>
          </div>
          <div
            class="col-12 col-sm-6 py-0 my-0"
            v-if="serverData?.customers?.length > 0"
          >
            <v-select
              v-model="formData.customer_id"
              label="Customer"
              item-text="text"
              item-value="index"
              :items="serverData.customers"
              clearable
              outlined
              dense
              required
              :error-messages="customer_idErrors"
              @input="$v.formData.customer_id.$touch()"
              @blur="$v.formData.customer_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-select
              v-model="formData.document_type"
              label="Document type"
              item-text="text"
              item-value="index"
              :items="serverData.document_types"
              clearable
              outlined
              dense
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-select
              v-model="formData.page_size"
              label="Page size"
              item-text="text"
              item-value="index"
              :items="serverData.page_sizes"
              clearable
              outlined
              dense
              required
              :error-messages="page_sizeErrors"
              @input="$v.formData.page_size.$touch()"
              @blur="$v.formData.page_size.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-select
              v-model="formData.module_id"
              label="Module"
              item-text="text"
              item-value="index"
              :items="serverData.modules"
              clearable
              outlined
              dense
              required
              :error-messages="module_idErrors"
              @input="$v.formData.module_id.$touch()"
              @blur="$v.formData.module_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-text-field
              v-model="formData.title"
              label="Title"
              dense
              outlined
              clearable
            ></v-text-field>
          </div>
          <div class="col-12 my-0 py-0 row">
            <div class="ps-3 my-0 py-0">
              <button
                class="btn btn--export-filter"
                @click.prevent="showInsertTableDialog"
                :disabled="!formData.module_id"
              >
                Insert table
              </button>
            </div>

            <div class="ps-3 my-0 py-0">
              <button
                class="btn btn--export-filter"
                @click.prevent="showInsertFormulaDialog"
              >
                Insert formula
              </button>
            </div>
          </div>
          <!--          <div class="col-12">{{ formData.table_columns }}</div>-->
          <!--          <div class="col-12 col-sm-6 py-0 my-0">-->
          <!--            <v-select-->
          <!--              :disabled="!formData.module_id"-->
          <!--              :items="variablesOfModule"-->
          <!--              item-text="text"-->
          <!--              item-value="value"-->
          <!--              @change="insertSelectedText"-->
          <!--              outlined-->
          <!--              dense-->
          <!--              clearable-->
          <!--              hint="Select variable to insert it to template"-->
          <!--              :error-messages="-->
          <!--                !formData.module_id ? ['Please, Select a module!'] : []-->
          <!--              "-->
          <!--            >-->
          <!--            </v-select>-->
          <!--          </div>-->
          <div class="col-12 row pt-4 pb-0 px-5">
            <div
              class="px-1 py-1"
              v-for="variable in formData.body_formulas"
              :key="variable.text"
            >
              <v-chip
                link
                outlined
                @click="insertSelectedText(`%${variable.text}%`)"
                >{{ variable.text }}</v-chip
              >
            </div>
          </div>
          <div class="col-12 row pb-4 pt-0 px-5">
            <div
              class="px-1 py-1"
              v-for="variable in variablesOfModule"
              :key="variable.value"
            >
              <v-chip
                link
                outlined
                @click="insertSelectedText(variable.value)"
                >{{ variable.text }}</v-chip
              >
            </div>
          </div>

          <!--          <div class="col-12">-->
          <!--            <ckeditor-->
          <!--              ref="ckeditor"-->
          <!--              v-model="formData.template"-->
          <!--              :config="editorConfig"-->
          <!--              @ready="logEvent('ready', $event)"-->
          <!--              @focus="logEvent('focus', $event)"-->
          <!--              @blur="logEvent('blur', $event)"-->
          <!--              @input="logEvent('input', $event)"-->
          <!--            ></ckeditor>-->
          <!--          </div>-->
          <div class="col-12">
            <ckeditor
              ref="ckeditor"
              v-model="formData.template"
              :config="editorConfig"
            ></ckeditor>
          </div>
          <div class="col-12">
            <div class="row justify-center">
              <button
                type="reset"
                class="btn btn-light mr-3 px-5 py-3 ls1"
                @click="resetCreateForm"
              >
                Clear
              </button>
              <button
                type="reset"
                class="btn btn-secondary mr-3 px-5 py-3 ls1"
                @click="saveAsCopy"
              >
                Save As Copy
              </button>
              <button
                type="submit"
                class="btn btn-info px-5 py-3 ls1"
                @click.prevent="submitCreateForm"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <insert-table
      ref="insertColumn"
      :available-columns="columnsLeftAfterSelection"
      :selected-columns="formData.table_columns"
      :insert-table-to-template="setTableColumnsWithAddingOrder"
      :available-formulas="serverData.formulas"
    />
    <insert-formula
      ref="insertFormula"
      :available-formulas="serverData.formulas"
      :selected-formulas="formData.body_formulas"
      :insert-formula-to-template="setFormulaConfiguration"
    />
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import validationMixin from "@/own/mixins/validationMixin";
import { required, requiredIf } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import CKEditor from "ckeditor4-vue";
import InsertTable from "./InsertTable.vue";
import InsertFormula from "./InsertFormula.vue";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      organization_id: {
        required: requiredIf(function () {
          return this.serverData?.organizations?.length > 0;
        }),
      },
      customer_id: {
        required: requiredIf(function () {
          return this.serverData?.customers?.length > 0;
        }),
      },
      module_id: { required },
      page_size: { required },
    },
  },
  components: { ckeditor: CKEditor.component, InsertTable, InsertFormula },
  name: "EditItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    events: [],
    editorData: "<p>Content of the editor.</p>",
    editorConfig: {
      // The configuration of the editor.
      versionCheck: false,
      toolbar: [
        {
          name: "document",
          items: [
            "Source",
            "-",
            "Preview",
            "Print",
            "-",
            // "Templates"
          ],
        },
        {
          name: "clipboard",
          items: [
            "Cut",
            "Copy",
            "Paste",
            "PasteText",
            "PasteFromWord",
            "-",
            "Undo",
            "Redo",
          ],
        },
        {
          name: "editing",
          items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"],
        },
        {
          name: "forms",
          items: [
            // "Form",
            // "Checkbox",
            // "Radio",
            // "TextField",
            // "Textarea",
            // "Select",
            "Button",
            "ImageButton",
            "HiddenField",
          ],
        },
        {
          name: "basicstyles",
          items: [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "Subscript",
            "Superscript",
            "-",
            "RemoveFormat",
          ],
        },
        {
          name: "paragraph",
          items: [
            "NumberedList",
            "BulletedList",
            "-",
            "Outdent",
            "Indent",
            "-",
            "Blockquote",
            "CreateDiv",
            "-",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock",
            "-",
            "BidiLtr",
            "BidiRtl",
            // "Language",
          ],
        },
        // { name: "links", items: ["Link", "Unlink", "Anchor"] },
        {
          name: "insert",
          items: [
            "Image",
            "Table",
            "HorizontalRule",
            "Smiley",
            "SpecialChar",
            "PageBreak",
            // "Iframe",
          ],
        },
        { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
        { name: "colors", items: ["TextColor", "BGColor"] },
        { name: "tools", items: ["Maximize", "ShowBlocks"] },
        {
          name: "about",
          items: ["About", "DocProps", "CreatePlaceholder"],
        },
      ],
      fullPage: true,
      allowedContent: true,
      height: 300,
      extraPlugins: [
        "docprops",
        "placeholder",
        "about",
        "a11yhelp",
        "basicstyles",
        "bidi",
        "blockquote",
        "clipboard",
        "colorbutton",
        "colordialog",
        "contextmenu",
        "copyformatting",
        "dialogadvtab",
        "div",
        "elementspath",
        "enterkey",
        "entities",
        "exportpdf",
        "filebrowser",
        "find",
        "floatingspace",
        "font",
        "format",
        "forms",
        "horizontalrule",
        "htmlwriter",
        "iframe",
        "image",
        "indentblock",
        "indentlist",
        "justify",
        "language",
        "link",
        "list",
        "liststyle",
        "magicline",
        "maximize",
        "newpage",
        "pagebreak",
        "pastefromword",
        "pastetext",
        "preview",
        "print",
        "removeformat",
        "resize",
        "save",
        "scayt",
        "selectall",
        "showblocks",
        "showborders",
        "smiley",
        "sourcearea",
        "specialchar",
        "stylescombo",
        "tab",
        "table",
        "tabletools",
        "templates",
        "toolbar",
        "undo",
        "wysiwygarea",
        "tableresize",
        // "imageresize",
        "image2,uploadimage",
        // 'easyimage',
        "colordialog,tableresize",
      ],
      resize_enable: true,
    },
    dialog: false,
    is_table_inserted: false,
    formData: {
      id: null,
      organization_id: null,
      customer_id: null,
      module_id: null,
      title: null,
      template: "",
      table_columns: [],
      body_formulas: [],
      page_size: null,
      document_type: null,
    },
    serverData: null,
  }),
  computed: {
    isDataLoadedFromServer() {
      return !!this.serverData;
    },
    variablesOfModule() {
      if (!this.formData.module_id) {
        return [];
      }
      return this.serverData.dynamic_values.find(
        (mod) => mod.module_id == this.formData.module_id
      )?.fields;
    },
    tableColumnsOfModule() {
      if (!this.formData.module_id) {
        return [];
      }
      return this.serverData.dynamic_values.find(
        (mod) => mod.module_id == this.formData.module_id
      )?.table_keys;
    },
    organization_idErrors: function () {
      return this.handleFormValidation("organization_id", this);
    },
    customer_idErrors: function () {
      return this.handleFormValidation("customer_id", this);
    },
    module_idErrors: function () {
      return this.handleFormValidation("module_id", this);
    },
    page_sizeErrors: function () {
      return this.handleFormValidation("page_size", this);
    },
    columnsLeftAfterSelection() {
      return this.tableColumnsOfModule.filter(
        (item1) =>
          !this.formData.table_columns.some(
            (item2) => item2.value === item1.value
          )
      );
    },
  },
  methods: {
    setFormulaConfiguration(formulas) {
      this.formData.body_formulas = formulas;
    },
    showInsertFormulaDialog() {
      this.$refs.insertFormula.toggleModal();
    },
    saveAsCopy() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      const data = this.formData;
      ApiService.post("/settings/dynamic_documents/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: "The record has been created",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    showInsertTableDialog() {
      this.$refs.insertColumn.toggleModal();
    },
    setTableColumnsWithAddingOrder(data) {
      data = data.map((a, i) => {
        a["order"] = i;
        return a;
      });
      this.formData.table_columns = data;
      if (!this.is_table_inserted) {
        this.insertSelectedText("%table%");
        this.is_table_inserted = true;
      }
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/settings/dynamic_documents/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = this.serverData.document;
          !this.formData.body_formulas
            ? (this.formData.body_formulas = [])
            : null;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },
    insertSelectedText(data) {
      const editor = this.$refs.ckeditor.instance;
      if (editor && data) {
        editor.insertHtml(`${data}`);
        editor.focus();
      }
    },
    // logEvent: function (eventName, event) {
    //   if (this.events.length > 19) {
    //     this.events.pop();
    //   }
    //   const eventData = {
    //     name: eventName,
    //     timestamp: this.getCurrentTimestamp(),
    //   };
    //   this.events.unshift(eventData);
    //   // console.log(eventData.timestamp, eventData.name, event);
    // },
    getCurrentTimestamp: function () {
      return new Intl.DateTimeFormat("en", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(new Date());
    },
    clearEventsLog: function () {
      this.events = [];
    },
    toggleModal() {
      if (!this.dialog) {
        this.loadDataFromServer();
      } else {
        this.serverData = null;
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      const data = this.formData;
      ApiService.post("/settings/dynamic_documents/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: "The record has been updated",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.resetCreateForm();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.formData = {
        id: null,
        organization_id: null,
        customer_id: null,
        module_id: null,
        title: null,
        template: "",
        table_columns: [],
        body_formulas: [],
        page_size: null,
        document_type: null,
      };
      this.clearEventsLog();
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  watch: {
    "formData.template": function (val) {
      if (val.includes("%table%")) {
        this.is_table_inserted = true;
      } else {
        this.is_table_inserted = false;
      }
    },
  },
};
</script>
<style></style>
